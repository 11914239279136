<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-tabs
          background-color="transparent"
          centered
          class="mb-5"
          show-arrows
        >
          <v-tab
            v-for="(category, i) in categories"
            :key="i"
            @click="select(category)"
          >
            <h3
              v-text="category.text"
            />
          </v-tab>
        </v-tabs>

        <v-responsive min-height="700">
          <v-container>
            <transition-group
              tag="v-row"
              name="fade-transition"
            >
              <v-col
                v-for="project in computedProjects"
                :key="project.img"
                cols="12"
                class="gallery-card"
                md="4"
              >
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-card
                      :img="`/static/${project.img}`"
                      :to="project.to"
                      class="white--text overflow-hidden"
                      dark
                      height="300"
                      width="600"
                      hover
                    >
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                          z-index="0"
                        />
                      </v-fade-transition>
                      <v-slide-y-reverse-transition>
                        <v-footer
                          absolute
                          class="justify-center"
                          height="45"
                        >
                          <div class="title">
                            {{ project.name }}
                          </div>
                        </v-footer>
                      </v-slide-y-reverse-transition>
                    </v-card>
                  </template>
                </v-hover>
              </v-col>
            </transition-group>
          </v-container>
        </v-responsive>

        <v-fade-transition mode="out-in">
          <v-overlay
            v-if="overlay"
            fixed
            opacity=".9"
          >
            <v-btn
              color="transparent"
              depressed
              fab
              fixed
              large
              right
              top
              @click="overlay = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>

            <v-img
              :src="`/static/${overlay}`"
              width="800"
              max-width="90vw"
            />
          </v-overlay>
        </v-fade-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      category: null,
      categories: [
        {
          'text': 'Ολα τα διπλωματα',
          'filter': null
        },
        {
          'text': 'Μηχανες',
          'filter': 1
        },
        {
          'text': 'Επαγγελματικα',
          'filter': 2
        }
      ],
      overlay: false,
      projects: [
        {
          to: '/AutoDiploma',
          'name': 'Αυτοκίνητα',
          'img': 'car.png',
          'categories': []
        },
        {
          to: '/fortigoDiploma',
          'name': 'Φορτηγά',
          'img': 'truck.png',
          'categories': [2]
        },
        {
          to: '/leofDiploma',
          'name': 'Λεωφορεία',
          'img': 'leof.png',
          'categories': [2]
        },
        {
          to: '/ntalikaDiploma',
          'name': 'Ρυμουλκούμενο',
          'img': 'ntalika.jpg',
          'categories': [2]
        },
        {
          to: '/taxiDiploma',
          'name': 'ΤΑΧΙ',
          'img': 'taxi.png',
          'categories': [2]
        },
        {
          to: '/epagelmatikoDiploma',
          'name': 'Π.Ε.Ι.',
          'img': 'epagelmatiko.jpg',
          'categories': [2]
        },
        {
          to: '/amDiploma',
          'name': 'Κατηγορία ΑΜ',
          'img': 'am.png',
          'categories': [1]
        },
        {
          to: '/a1Diploma',
          'name': 'Κατηγορία Α1',
          'img': 'a1.png',
          'categories': [1]
        },
        {
          to: '/a2Diploma',
          'name': 'Κατηγορία Α2',
          'img': 'a2.png',
          'categories': [1]
        },
        {
          to: '/aDiploma',
          'name': 'Κατηγορία Α',
          'img': 'a.jpg',
          'categories': [1]
        }
      ]
    }),

    computed: {
      computedProjects () {
        return !this.category
          ? this.projects
          : this.projects.filter(p => p.categories.includes(this.category))
      }
    },

    methods: {
      select (category) {
        this.category = category.filter
      }
    }
  }
</script>

<style scoped>
  .gallery-card {
    transform-origin: center center 0;
    transition: .3s cubic-bezier(.25,.8,.50,1);
  }

  .fade-transition-leave,
  .fade-transition-leave-active,
  .fade-transition-leave-to {
    display: none;
  }
</style>
